<template>

    
  
      <b-row :key="componentKey">

        <b-col cols="12" sm="12" md="12" lg="3" xl="3">

          <rate
    
            :user-id="userId"
             :token-auth="tokenAuth"

          />

          <e-q-coins
    
            :user-id="userId"
            :token-auth="tokenAuth"

          />

          </b-col>

        <b-col cols="12" sm="12" md="12" lg="4" xl="4">

          <listado-contactos
            :key="componentKeyListadoContactos"
            :user-id="userId"
             :token-auth="tokenAuth"
             :columnas="columnas"
          />


        </b-col>

        <b-col cols="12" sm="12" md="12" lg="5" xl="5">

          <listado-remesas
            :key="componentKeyListadoRemesas"
            :user-id="userId"
             :token-auth="tokenAuth"
             :columnas="columnas"
          />


          </b-col>

        <modal-remesas-contactos v-if="openModalRemesasContactos"   :key="componentKeyReiniciarRemesasContactos"   :user-id="userId" :token-auth="tokenAuth"  />
   
  
        <a v-if="isMobile"  @click="openModal()" class="float" >

     
            <svg width="25" class="my-float" style="margin-top: 18px;" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 45.402 45.402" ><g><path d="M41.267 18.557H26.832V4.134C26.832 1.851 24.99.0 22.707.0c-2.283.0-4.124 1.851-4.124 4.135v14.432H4.141c-2.283.0-4.139 1.851-4.138 4.135-.001 1.141.46 2.187 1.207 2.934.748.749 1.78 1.222 2.92 1.222h14.453V41.27c0 1.142.453 2.176 1.201 2.922.748.748 1.777 1.211 2.919 1.211 2.282.0 4.129-1.851 4.129-4.133V26.857h14.435c2.283.0 4.134-1.867 4.133-4.15C45.399 20.425 43.548 18.557 41.267 18.557z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>  



</a>

<a v-else  v-b-tooltip.hover.left="'Agregar nuevo contacto'" @click="openModal()" class="float" >

    <svg width="25" class="my-float" style="margin-top: 18px;" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 45.402 45.402" ><g><path d="M41.267 18.557H26.832V4.134C26.832 1.851 24.99.0 22.707.0c-2.283.0-4.124 1.851-4.124 4.135v14.432H4.141c-2.283.0-4.139 1.851-4.138 4.135-.001 1.141.46 2.187 1.207 2.934.748.749 1.78 1.222 2.92 1.222h14.453V41.27c0 1.142.453 2.176 1.201 2.922.748.748 1.777 1.211 2.919 1.211 2.282.0 4.129-1.851 4.129-4.133V26.857h14.435c2.283.0 4.134-1.867 4.133-4.15C45.399 20.425 43.548 18.557 41.267 18.557z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>  

</a>
      
  
  
       </b-row>

  
  </template>
  
  <script>
  import { BRow, BCol, VBTooltip,VBPopover } from 'bootstrap-vue'
  import ListadoRemesas from './ListadoRemesas.vue'
  import ListadoContactos from './ListadoContactos.vue'
  import ModalRemesasContactos from './ModalRemesasContactos.vue'
  import Rate from './Rate.vue'
  import EQCoins from './EQCoins.vue'
  export default {
    components: {
      BRow,
      ModalRemesasContactos,
      ListadoRemesas,
      ListadoContactos,
      BCol,
      Rate,
      EQCoins,
    },
    directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    data() {
  
      const datos_json = JSON.parse(this.$userGlobal);
  
  

            const mq = window.matchMedia( "(min-width: 990px)" );
          let columnas=4;
  
          if (!mq.matches) {
  
            columnas =3;
            }
  
  
      return {
        isMobile:this.$isMobile,
        userId: datos_json.userId,
        tokenAuth: this.$tokenGlobal,
        componentKeyReiniciarRemesasContactos:0,
        componentKey:0,
        componentKeyListadoRemesas:0,
        componentKeyListadoContactos:0,
        openModalRemesasContactos:false,
        columnas:columnas

      }
    },
    created() {
      // data
  
      /*
      this.$http.get('/analytics/data')
        .then(response => { this.data = response.data })
  
        */
    },
    activated() {

this.$eventBus.$emit('reiniciarMenu1')
 this.$eventBus.$emit('reiniciarMenu2')


},
    mounted() {
  

  
   this.$eventBus.$on('reiniciarAgregarContactosRemesas', () => {
      this.componentKeyReiniciarRemesasContactos += 1
      this.openModalRemesasContactos=false;
    });


    this.$eventBus.$on('reiniciarRemesas', () => {
      this.componentKey += 1
    })

    this.$eventBus.$on('reiniciarListadoRemesas', () => {
      this.componentKeyListadoRemesas += 1
    })

    this.$eventBus.$on('reiniciarListadoContactos', () => {
      this.componentKeyListadoContactos += 1
    })

  
    
  
      document.title = 'Remesas'
  
    },
    methods: {

        openModal(){

            this.openModalRemesasContactos=true;

        }

  
    },
  }
  </script>
  
  <style lang="scss" >
  
  
  
  </style>
  