<template>

    <div>



    <b-modal
      ref="modalEditContactos"
      centered
      :size="size"
      hide-footer
      @hidden="closeModalEditContactos"
      :title="title"
    >
      <div class="d-block text-center">
       
        <b-form
          autocomplete="off"
          @submit.prevent="onSubmitEditContact"
          >

          <b-row>


        
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        
          <h4 class="font-weight-bolder">
              Información personal
            </h4>
      
            <hr style="margin: 10px;">
        </b-col>

        <b-col
              sm="12"
              md="12"
              lg="4"
              xl="4"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Seleccione un país (*):
              </p>

              <b-form-select
                v-model="countryCodeEdit"
                required
                :options="optionsCountry"
                @change="onChange($event)"
              />

            </b-col>



 

        <b-col cols="12" sm="12" md="12" lg="4" xl="4" style="margin-bottom: 10px;">
          <p class="textLabel"><strong>Tipo de documento (*): </strong> </p>
          
          <b-form-select
                v-model="typeDocumentEdit"
                :disabled="isDisabled"
                required
                :options="optionsTypeDocument"
              />


        </b-col>


        <b-col cols="12" sm="12" md="12" lg="4" xl="4" style="margin-bottom: 10px;">
          <p class="textLabel"><strong>Cédula/DNI (*): </strong> </p>
         
          <b-form-input
              id="dni"
              v-model="dniEdit"
              placeholder="Ingrese la cédula/DNI"
              required
              @keypress="NumbersOnly"
                     type="tel"
              :readonly="isDisabled"
    
            />

        </b-col>

        <b-col cols="12" sm="12" md="12" lg="6" xl="6" style="margin-bottom: 10px;">
          <p class="textLabel"><strong>Nombres (*): </strong> </p>
         
          <b-form-input
              v-model="firstNameEdit"
              placeholder="Ingrese los nombres"
              required
              type="text"
              :readonly="isDisabled"
              @keypress="isLetter"
            />

        </b-col>

        <b-col cols="12" sm="12" md="12" lg="6" xl="6" style="margin-bottom: 10px;">
          <p class="textLabel"><strong>Apellidos (*): </strong> </p>
         
          <b-form-input
              v-model="lastNameEdit"
              placeholder="Ingrese los apellidos"
              required
              type="text"
              :readonly="isDisabled"
              @keypress="isLetter"
            />

        </b-col>

  
        <b-col cols="12" sm="12" md="12" lg="6" xl="6" style="margin-bottom: 10px;">
          <p class="textLabel"><strong>Celular (*): </strong> </p>
          
          <MazPhoneNumberInput
                                    v-model="phoneNumberEdit"
                                    type="tel"
                                    :default-country-code="countryCodeISO3166"
                                    :only-countries="[countryCodeISO3166]"
                                    no-flags
                                    no-example
                                    :readonly="isDisabled"
                                    required
                                    show-code-on-list
                                    size="md"
                                    :translations="{
                                      countrySelectorLabel: 'Codigo',
                                      countrySelectorError: 'Seleccione un pais',
                                      phoneNumberLabel: 'Número celular',
                                    }"
                                    @update="results = $event"
                                  />

         
  

        </b-col>

        <b-col cols="12" sm="12" md="12" lg="6" xl="6" style="margin-bottom: 10px;">
          <p class="textLabel"><strong>Email (Opcional): </strong> </p>
          
          <b-form-input
                  v-model="emailEdit"
                  type="email"  
                  placeholder="Ingrese un email (Opcional)"
                />
        
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top: 5px;">
        
        <h4 class="font-weight-bolder">
            Información bancaria
          </h4>
    
          <hr style="margin: 10px;">
      </b-col>

      <b-col cols="12" sm="12" md="12" lg="6" xl="6" style="margin-bottom: 10px;">

        <p class="textLabel">
                Institución bancaria (*):
              </p>
              
              <v-select
                v-model="nameBankEdit"
                placeholder="Seleccione un banco"
                :options="options"
                :required="!bankCodeEdit"
                label="Banco"
                :disabled="isDisabled"
                @input="setSelected"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!bankCodeEdit"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>

              </v-select>

        </b-col>

        <b-col cols="12" sm="6" md="6" lg="3" xl="3" style="margin-bottom: 10px;">

          <p class="textLabel">
                Tipo de cuenta (*):
              </p>

              <b-form-select
                v-model="typeAccountEdit"
                :disabled="isDisabled"
                required
                :options="optionsCuenta"
              />

          </b-col>

          <b-col cols="12" sm="6" md="6" lg="3" xl="3" style="margin-bottom: 10px;">

            <p class="textLabel">
                Nro. cuenta (*):
              </p>

              <b-form-input
                v-model="numberAccountEdit"
                placeholder="Ingrese el nro. cuenta"
                required
                @keypress="NumbersOnly"
                     type="tel"
                :disabled="isDisabled"
        
              />

          </b-col>

          <b-col
              sm="12"
              md="12"
              lg="2"
              xl="2"
            ></b-col>

            <b-col
              sm="12"
              md="12"
              lg="8"
              xl="8"
            >

              <p
                class="textLabel"
                style="text-align: center;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

              <PincodeInput
                v-model="pinEditar"
                required
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :readonly="isDisabled"
              />

            </b-col>

               <b-col
              sm="12"
              md="12"
              lg="2"
              xl="2"
            ></b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
            >

              <b-button
                id="btn_registrar"
                ref="btn_registrar"
                type="submit"
                variant="primary"
                :disabled="isDisabled"
              >

                Editar contacto

              </b-button>

            </b-col>

          </b-row>

          </b-form>


        </div>


        </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
    VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BForm, BAlert, BFormSelect, BFormInput
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

  import PincodeInput from 'vue-pincode-input'

  export default {
    components: {
      BButton,
      vSelect,
      BFormSelect,
      BFormInput,
      BForm,
      BRow,
      BCol,
      BAlert,
      BCard,
      PincodeInput,
    },
      directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth','countryCode','banks','typeDocument','typeAccount', 'contactId','firstName', 'lastName','numberAccount', 'nameBank', 'country', 'dni','email','phoneNumber', 'bankCode'],
    data() {
  
  
      return {
       pinEditar:"",
       countryCodeISO3166:"VE",
       countryCodeEdit: this.countryCode,
       typeDocumentEdit: this.typeDocument,
       typeAccountEdit: this.typeAccount,
       bankCodeEdit: this.bankCode,
       emailEdit: this.email,
       nameBankEdit: this.nameBank,
       numberAccountEdit: this.numberAccount,
       size:"lg",
       dniEdit: this.dni,
       firstNameEdit: this.firstName,
       lastNameEdit: this.lastName,
       isPhoneValid:false,
       results: null,
       phoneNumberEdit: this.phoneNumber,
       title:"Edición de contacto",
       isDisabled:false,
       optionsCountry: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'VEN', text: 'Venezuela' },
      ],
       optionsTypeDocument: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'Nacional', text: 'Nacional' },
        { value: 'Extranjero', text: 'Extranjero' },
        { value: 'Pasaporte', text: 'Pasaporte' },

      ], optionsCuenta: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'Ahorros', text: 'Ahorros' },
        { value: 'Corriente', text: 'Corriente' },

      ],
      options: this.banks,
      banksVE: [],
      banksEc: [],
      banksUS: [],


      
      }
    },
    computed: {
  
  
  
    },
    watch: {

      results(newValue) {
        this.isPhoneValid= newValue.isValid;
      if (this.isPhoneValid) {

        this.phoneNumberEdit = newValue.e164
      } else {

        this.phoneNumberEdit = ''
      }
    }
  
  
  
    },
    mounted() {
  
  


        this.$refs.modalEditContactos.show()
  
        
  
  
  
    },
    methods: {

      NumbersOnly(evt) {
evt = (evt) || window.event
const charCode = (evt.which) ? evt.which : evt.keyCode
if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
evt.preventDefault()
} else {
return true
}
},

     isLetter(e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
      e.preventDefault() // If not match, don't add to input text
    },
    setSelected(value) {
      this.bankCodeEdit = value.Codigo;
      this.nameBankEdit= value.Banco;
  
    },

      onChange(opcion) {

           
          this.countryCodeEdit =opcion;
      

          let consulta=false;
          this.options=[];

          switch(this.countryCodeEdit) {
                case "EC":

                this.countryCodeISO3166="ECU";
                if(Number(this.banksEc.length) === Number("0")){
                  consulta=true;
               

                }else{
                  consulta= false;
                  this.options= this.banksEc;
  
        

                }
          

                break;

                case "VEN":

                this.countryCodeISO3166="VE";
            
                if(Number(this.banksVE.length) === Number("0")){
                  consulta=true;
               

                }else{
                  consulta= false;
                  this.options= this.banksVE;
    
                }


                break;

                case "US":
                this.countryCodeISO3166="USA";
                if(Number(this.banksUS.length) === Number("0")){
                  consulta=true;
                  

                }else{
                  consulta= false;
                  this.options= this.banksUS;
    
 

                }

                break;

              }



        if(consulta){

          this.$swal({
                html:
                '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Cargando bancos, espere',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'center',
              })
             

          this.$https.post('/banks/listBanks/', { countryCode: this.countryCodeISO3166 }).then(response => {

            this.$swal.close()

      

                  if (response.data.code == 200) {

                    this.options= response.data.banks;
     

                   
                    switch(this.countryCodeEdit) {
                          case "EC":
                
                          this.countryCodeISO3166="ECU";
                          this.banksEc= response.data.banks;
                      


                          break;

                          case "VEN":
                 

                          this.countryCodeISO3166="VE";
                          this.banksVE= response.data.banks;

             


                          break;

                          case "US":
                          this.countryCodeISO3166="USA";
                          this.banksUS= response.data.banks;


                          break;

                        }

                  
                  } else {
                    this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })


                  }
                  }).catch(error => {

                
                  })

        } 


        


      },

     closeModalEditContactos(){

     
         
           this.$eventBus.$emit('reiniciarEditContactos');
    


    },
    onSubmitEditContact(event){
      event.preventDefault()

      if(this.isPhoneValid){

        
            const userId_json = {
              userId: this.userId,contactId: this.contactId,  pin: this.pinEditar,email: this.emailEdit,bankCode:this.bankCodeEdit,typeAccount:this.typeAccountEdit,numberAccount:this.numberAccountEdit, firstName: this.firstNameEdit,lastName:this.lastNameEdit,phoneNumber:this.phoneNumberEdit,nameBank:this.nameBankEdit,  countryCode: this.countryCodeEdit,  typeDocument: this.typeDocumentEdit, dni: this.dniEdit
            

            }
            const user_string = JSON.stringify(userId_json)




            this.isDisabled = true

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)



            document.getElementById('btn_registrar').innerHTML = '<img style="margin-left:10px; " src="/img/loading2.svg" alt="Loading" />Editando contacto'

            this.$https.post('/remesas/editContact/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
              this.isDisabled = false
   

              document.getElementById('btn_registrar').innerHTML = "Registrar contacto";

              if (response.data.code == 200) {


                    this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });

                this.$refs.modalEditContactos.hide()
  
        
                
             
                this.$eventBus.$emit('reiniciarListadoContactos')
 

          




              } else {
           

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                if (response.data.code == 401) {
                  
                  localStorage.removeItem('userData')


            
                

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                } else if (response.data.code == 502) {
                  this.dniEdit = ''
                } else if (response.data.code == 503) {
                  this.pinEditar = ''
                }
              }
            }).catch(error => {
              this.$toast.error("Ocurrió un error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })

     


            }else{
              this.phoneNumberEdit="";

                  this.$toast.error("Por favor ingrese un número celular válido para el pais seleccionado", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

            }


    }


  
     
    }

    
  }
  </script>
  
  <style lang="scss">
  
  

  </style>
  