<template>

    <div>



    <b-modal
      ref="modalRemesasContactos"
      centered
      :size="size"
      hide-footer
      @hidden="closeModalRemesasContactos"
      :title="title"
    >
      <div class="d-block text-center">

       

      <b-row v-if="showCountry">

        <b-col cols="12" sm="12" md="12" lg="12" xl="12">

       
          <div class="radio-tile-group">
                          <div class="input-container">
                            <input id="VEN" class="radio-button" type="radio" @change="onChange($event)" value="VEN" name="radio" />
                            <div class="radio-tile">
                              <p class="radio-tile-label" style="margin-bottom: 5px">BS./</p>
                              <div class="icon walk-icon">


                                     <img style="width: 30px;" alt="Venezuela" src="/img/venezuela.svg">                 
                              </div>
                              <label for="VEN" class="radio-tile-label" style="margin-top: 6px;">Venezuela</label>
                            </div>
                          </div>

                
              
                 </div>

          </b-col>

          <b-col sm="12" md="12" lg="12" xl="12" style="text-align:right;">

              <b-button
                style="margin-top:10px"
                variant="primary"
                :disabled="isDisabledSiguiente"
                @click="hideCountry()">

                Siguiente  <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/> 
                  

               </b-button>
          </b-col>

            
       

      </b-row>


                    
        <b-form
        v-else-if="showGeneral"
          autocomplete="off"
          @submit.prevent="onSubmitGeneral"
          >

     
          <b-row>


            <b-col cols="12" sm="12" md="12" lg="6" xl="6">

   
      
            <p class="textLabel"><strong>Tipo de documento (*): </strong> </p>
          
          <b-form-select
                v-model="typeDocument"
                :disabled="isDisabled"
                required
                :options="optionsTypeDocument"
                style="margin-bottom: 10px;"
              />

              </b-col>

              <b-col cols="12" sm="12" md="12" lg="6" xl="6">


              <p class="textLabel"><strong>Cédula/DNI (*): </strong> </p>
         
                <b-form-input
                    id="dni"
                    v-model="dni"
                    placeholder="Ingrese la cédula/DNI"
                    required
                    @keypress="NumbersOnly"
                     type="tel"
                    :readonly="isDisabled"
                    style="margin-bottom: 10px;"
                  />

                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="6" xl="6">


                  <p class="textLabel"><strong>Nombres (*): </strong> </p>
         
                    <b-form-input
                        v-model="firstName"
                        placeholder="Ingrese los nombres"
                        required
                        type="text"
                        :readonly="isDisabled"
                        @keypress="isLetter"
                        style="margin-bottom: 10px;"
                      />

                      </b-col>

                      <b-col cols="12" sm="12" md="12" lg="6" xl="6">


                      <p class="textLabel"><strong>Apellidos (*): </strong> </p>
                  
                  <b-form-input
                      v-model="lastName"
                      placeholder="Ingrese los apellidos"
                      required
                      type="text"
                      :readonly="isDisabled"
                      @keypress="isLetter"
                      style="margin-bottom: 10px;"
                    />

                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">


                  <p class="textLabel"><strong>Ciudad (*): </strong> </p>
         
                  <b-form-input
                      id="city"
                      v-model="city"
                      placeholder="Ingrese la ciudad"
                      required
                      type="text"
                      :readonly="isDisabled"
                      @keypress="isLetter"
                      style="margin-bottom: 10px;"
                    />

                    </b-col>
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">


                    <p class="textLabel"><strong>Celular (*): </strong> </p>

                    <MazPhoneNumberInput
                                              v-model="phoneNumber"
                                              type="tel"
                                              :default-country-code="countryCodeISO3166"
                                              :only-countries="[countryCodeISO3166]"
                                              no-flags
                                              no-example
                                              :readonly="isDisabled"
                                              required
                                              show-code-on-list
                                              size="md"
                                              :translations="{
                                                countrySelectorLabel: 'Codigo',
                                                countrySelectorError: 'Seleccione un pais',
                                                phoneNumberLabel: 'Número celular',
                                              }"
                                              @update="results = $event"
                                              style="margin-bottom: 10px;"
                                            />

                                            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12">


          <p class="textLabel"><strong>Email (Opcional): </strong> </p>
          
          <b-form-input
                  v-model="email"
                  type="email"  
                  placeholder="Ingrese un email (Opcional)"
                  style="margin-bottom: 10px;"
                />

            </b-col>


            



            <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex" >

                  <b-button
                    style="margin-right:10px"
                    variant="outline-secondary"
                    :disabled="isDisabledSiguiente"
                    @click="showOptionsCountry()">

                      <feather-icon  icon="ArrowLeftCircleIcon" size="15" style="margin-right: 5px;"/> Atras
                      

                  </b-button>



                          <b-button
                            id="btn_registrar"
                            ref="btn_registrar"
                            type="submit"
                            variant="primary"
                            class="animacion_button"
                          >

                            Siguiente

                          </b-button>

                  </b-col>


          </b-row>
  
          </b-form>

          <b-form
           v-else
          autocomplete="off"
          @submit.prevent="onSubmit"
          >

     
          <b-row>

            <b-col cols="12" col-sm="12" col-md="12" col-lg="12" col-xl="12">
 


                <p class="textLabel">
                Institución bancaria (*):
              </p>
              
              <v-select
                v-model="bank"
                placeholder="Seleccione un banco"
                :options="options"
                :required="!bankCode"
                label="Banco"
                :disabled="isDisabled"
                @input="setSelected"
                style="margin-bottom: 10px;"
              >
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    :required="!bankCode"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>

              </v-select>


              </b-col>

              <b-col cols="12" col-sm="12" col-md="6" col-lg="6" col-xl="6">
 

              <p class="textLabel">
                Tipo de cuenta (*):
              </p>

              <b-form-select
                v-model="typeAccount"
                :disabled="isDisabled"
                required
                :options="optionsCuenta"
                style="margin-bottom: 10px;"
              />


              </b-col>

              <b-col cols="12" col-sm="12" col-md="6" col-lg="6" col-xl="6">
 

              <p class="textLabel">
                Nro. cuenta (*):
              </p>

              <b-form-input
                v-model="numberAccount"
                placeholder="Ingrese el nro. cuenta"
                required
                @keypress="NumbersOnly"
                     type="tel"
                :disabled="isDisabled"
                style="margin-bottom: 10px;"
              />

              </b-col>

              <b-col cols="12" col-sm="12" col-md="12" col-lg="12" col-xl="12">
 
              <p
                class="textLabel"
                style="text-align: center;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

              <PincodeInput
                v-model="pin"
                required
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :readonly="isDisabled"
              />

            

            </b-col>

            <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex" >

              <b-button
                style="margin-right:10px"
                variant="outline-secondary"
                :disabled="isDisabledSiguiente"
                @click="showOptionsGeneral()">

                  <feather-icon  icon="ArrowLeftCircleIcon" size="15" style="margin-right: 5px;"/> Atras
                  

               </b-button>


              
              <b-button
                id="btn_registrar"
                ref="btn_registrar"
                type="submit"
                variant="primary"
                :disabled="isDisabled"
              >

                Registrar

              </b-button>

              </b-col>

          </b-row>
  
          </b-form>




        </div>


        </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
    VBTooltip,VBPopover, BButton, BRow, BCol, BCard, BForm, BAlert, BFormSelect, BFormInput
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
  import PincodeInput from 'vue-pincode-input'

  
  export default {
    components: {
      BButton,
      vSelect,
      BFormSelect,
      BFormInput,
      BForm,
      BRow,
      BCol,
      BAlert,
      BCard,
      PincodeInput,
    },
      directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    props: ['userId','tokenAuth'],
    data() {
  
                          
  
      return {

       showCountry:true,
       showGeneral:false,
       pin:"",
       email:"",
       numberAccount:"",
       country:"",
       typeDocument:"",
       firstName:"",
       lastName:"",
       bankCode:"",
       size:"md",
       nameBank:"",
       phoneNumber:"",
       isPhoneValid:false,
       results: null,
       typeAccount:"",
       title:"Paso 1/3 - Seleccione un país",
       isDisabledSiguiente:true,
       isDisabled:false,
       optionsTypeDocument: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'Nacional', text: 'Nacional' },
        { value: 'Extranjero', text: 'Extranjero' },
        { value: 'Pasaporte', text: 'Pasaporte' },

      ], optionsCuenta: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: 'Ahorros', text: 'Ahorros' },
        { value: 'Corriente', text: 'Corriente' },

      ],
      options: [],
      banksVE: [],
      banksEc: [],
      banksUS: [],
      countryCode:"",
      countryCodeISO3166:"",
      dni:"",
      bank:"",
      city:""

      
      }
    },
    computed: {
  
  
  
    },
    watch: {

      results(newValue) {
        this.isPhoneValid= newValue.isValid;
      if (this.isPhoneValid) {

        this.phoneNumber = newValue.e164
      } else {

        this.phoneNumber = ''
      }
    }
  
  
  
    },
    mounted() {
  
  
this.size="sm";

        this.$refs.modalRemesasContactos.show()
  
        
  
  
  
    },
    methods: {
      NumbersOnly(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
     isLetter(e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
      e.preventDefault() // If not match, don't add to input text
    },
    setSelected(value) {
      this.bankCode = value.Codigo;
      this.nameBank= value.Banco;
  
    },

      onChange(event) {

           
          this.countryCode = event.target.value;
          if(this.countryCode === "VEN"){
            this.country= "Venezuela";
            this.countryCodeISO3166="VE";
       
          }else{
            if(this.countryCode === "US"){
              this.country= "Estados Unidos";
              this.countryCodeISO3166="USA";
            }
          }
          this.isDisabledSiguiente=false;

        


      },
      showOptionsGeneral(){
        this.showCountry = false;
        this.showGeneral=true;
        this.title="Paso 2/3 - Información General";
        this.size="md";
      },
      showOptionsCountry(){
        this.showCountry = true;
        this.showGeneral=false;
        this.isDisabledSiguiente=true;
        this.title="Paso 1/3 - Seleccione un país";
        this.size="sm";

      },

      hideCountry(){

        this.showCountry = false;
        this.showGeneral=true;
        this.size="md";
        let consulta=false;
        this.title="Paso 2/3 - Información personal";

        this.dni="";
        this.firstName="";
        this.lastName="";
        this.city="";
        this.phoneNumber="";
        this.email="";

          this.options=[];

          switch(this.countryCode) {
                case "EC":

                if(Number(this.banksEc.length) === Number("0")){
                  consulta=true;
               

                }else{
                  consulta= false;
                  this.options= this.banksEc;
                  
               

                }
          

                break;

                case "VEN":

                if(Number(this.banksVE.length) === Number("0")){
                  consulta=true;
               

                }else{
                  consulta= false;
                  this.options= this.banksVE;
               

                }


                break;

                case "US":
     

                if(Number(this.banksUS.length) === Number("0")){
                  consulta=true;
                  

                }else{
                  consulta= false;
                  this.options= this.banksUS;

 

                }

                break;

              }



        if(consulta){

          this.$swal({
                html:
                '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Cargando bancos, espere',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'center',
              })

          this.$https.post('/banks/listBanks/', { countryCode: this.countryCode }).then(response => {

            this.$swal.close()

      

                  if (response.data.code == 200) {

                    this.options= response.data.banks;

                   
                    switch(this.countryCode) {
                          case "EC":
                
           
                          this.banksEc= response.data.banks;
                      


                          break;

                          case "VEN":
                    
                      
                          this.banksVE= response.data.banks;

                    


                          break;

                          case "US":
                   
                          this.banksUS= response.data.banks;


                          break;

                        }

                  
                  } else {
                    this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })


                  }
                  }).catch(error => {

                  this.hideCountry(this.countryCode);
                  })

        } 


     },

      closeModalRemesasContactos(){

     
         
           this.$eventBus.$emit('reiniciarAgregarContactosRemesas');
    
          this.$eventBus.$emit('reiniciarRemesasContactosHome')

    },
    onSubmitGeneral(event){
      event.preventDefault();

      this.showGeneral=false;
      this.title="Paso 3/3 - Información financiera"
      this.size="sm";
      this.pin="";
      this.typeAccount="";
      this.numberAccount="";


    },
        onSubmit(event){
      event.preventDefault()

      if(this.isPhoneValid){

        
            const userId_json = {
              userId: this.userId,city:this.city, pin: this.pin,email: this.email,bankCode:this.bankCode,typeAccount:this.typeAccount,numberAccount:this.numberAccount, firstName: this.firstName,lastName:this.lastName,phoneNumber:this.phoneNumber,nameBank:this.nameBank,  countryCode: this.countryCode,  typeDocument: this.typeDocument, dni: this.dni
            

            }
            const user_string = JSON.stringify(userId_json)




            this.isDisabled = true

            const dataEncripted = this.$encryptBackoffice.encrypt(user_string)



            document.getElementById('btn_registrar').innerHTML = '<img style="margin-left:10px; " src="/img/loading2.svg" alt="Loading" />Registrando'

            this.$https.post('/remesas/createContact/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
              this.isDisabled = false
   

              document.getElementById('btn_registrar').innerHTML = "Registrar";

              if (response.data.code == 200) {


                    this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });

                this.$refs.modalRemesasContactos.hide()
  
        
                
             
                this.$eventBus.$emit('reiniciarListadoContactos')
 

          




              } else {
           

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                if (response.data.code == 401) {
                  
                  localStorage.removeItem('userData')


   
                  
                  

                

                  
                  

                  // Redirect to login page
                  this.$router.push({ name: 'auth-login' })
                } else if (response.data.code == 502) {
                  this.dni = ''
                } else if (response.data.code == 503) {
                  this.pin = ''
                }else if(response.data.code == 501){
                  this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
                }
              }
            }).catch(error => {
              this.$toast.error("Ocurrió un error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })

     


            }else{
              this.phoneNumber="";

                  this.$toast.error("Por favor ingrese un número celular válido para el pais seleccionado", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

            }


    }


  
     
    }

    
  }
  </script>
  
  <style lang="scss">
  

  
  </style>
  