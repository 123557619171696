<template>

      <b-row> 
  
        <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="padding:0px">
  
          <h4 class="font-weight-bolder" style="text-align: left !important;">
                              <feather-icon
                                            icon="ListIcon"
                                            size="21"
                                            class="color_icon"
                                            />
                                            Tasas de cambios
                        
                        </h4>

    

        </b-col>
  
  
  <b-col   cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:15px">
  
  
  
  
                    <b-skeleton-table
                         v-if="loading"
                        :rows="2"
                        :columns="columns"
                        :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>

              <div v-else class="table-responsive" style="margin-top:10px;     margin-bottom: 0px;">
                        <table class="table table-bordered" style="text-align: center;">

                        
                          <tbody>
                           <tr>
                  
                              <th class="background_table">País</th>
                              <th class="background_table"> Tasa</th>
                            
                            </tr>

                            <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">
                      
                                <td class="background_td_table"> {{ transaccion.Pais }}</td>
                                <td class="background_td_table"> {{ transaccion.rate }} {{ transaccion.currency }}</td>


                                </tr>


                          
                          </tbody>

                        </table>
                        </div>
  
  </b-col>
  
      </b-row>
 
      </template>
      
      <script>
      import {
        BRow, BCol, BCard,BSkeletonTable, BSkeleton
      } from 'bootstrap-vue'


      export default {
        components: {

          BSkeleton,

          
          BSkeletonTable,
          BRow,
          BCol,

          BCard,
 
        },
        props: ['userId','tokenAuth'],
        data() {
      


      
          return {
   

            loading: true,
             transactions:[],
             columns:2

      
          }
        },
        computed: {
      
        },
        watch: {

     
      
        },
        mounted() {
  
     
  
      
          this.loadTable();
      
      
      
      
      
        },
        methods: {

        loadTable(){
            

            this.$https.post('/remesas/exchangeRate/', { tokenAuth: this.tokenAuth}).then(response => {
             
       
                            if(response.data["code"] == 200){
                            
                           
                      
                             
      
                              this.transactions= response.data["transactions"];
       
                       
      
                              this.loading=false;
 
      
                           
        
                                
                              }else{
      
                 
                                  
      
      
                                        if (response.data.code == 401) {
      
                                          this.$toast.error(response.data.message, {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                  })
      
      
                                          
                                          localStorage.removeItem('userData')

                                            
                             
                                          
      
                 
      
                                          
                                          
      
                                          // Redirect to login page
                                          this.$router.push({ name: 'auth-login' })
                                        } else {

                                            this.loadTable();
      
      
                                        }
      
                              }
                       }).catch((error) => {
      
                                this.loadTable();
      
                           
                       });
          }
      
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      </style>