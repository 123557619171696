<template>
    <b-card>
      <b-row v-if="loading" style="margin-bottom:15px"> 
  
        <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
  
          <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
                <feather-icon
                          icon="ListIcon"
                          size="21"
                          class="color_icon"
                          /> Mis contactos de remesas
                  </h4>

        </b-col>
  
  <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
  <b-skeleton width="100%"  height="20px"></b-skeleton>
  
  </b-col>
  
  
  
  <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
  
  
  </b-col>
  
  <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
  
  <b-skeleton width="100%"  height="30px"></b-skeleton>
  
  </b-col>
  
  <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
  
  
  </b-col>
  
  <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
  
  
      </b-col>
  
      <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
  
      <b-skeleton width="100%"  height="30px"></b-skeleton>
  
      </b-col>
  
      <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
  
  
      </b-col>
  
  <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">
  
  
  
  
                    <b-skeleton-table
                :rows="2"
                :columns="columns"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
  
  </b-col>
  
  <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
  
  
  </b-col>
  
  <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
  
  <b-skeleton width="100%"  height="30px"></b-skeleton>
  
  </b-col>
  
  <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
  
  
  </b-col>
  
  <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
  
  
  </b-col>
  
  <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
  <b-skeleton width="100%"  height="30px"></b-skeleton>
  
  
  </b-col>
  
  <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
  <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>
  
  
  </b-col>
  
  <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
  <b-skeleton width="100%"  height="30px"></b-skeleton>
  
  
  </b-col>
  
  
  <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
  
  
  </b-col>
        
      
       </b-row>
      
       <b-row v-else-if="code200">


        
        <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
  
          <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
                <feather-icon
                          icon="ListIcon"
                          size="21"
                          class="color_icon"
                          /> Mis contactos de remesas
                  </h4>
      
        </b-col>
      
       <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">
      
        
        <p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                                      <feather-icon
                                                                      icon="RefreshCcwIcon"
                                                                      size="16"
                                                                      style="margin-right:5px"
                                                                      />  </p>
      
      
      
       </b-col>
  
  
      
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto;padding: 0px;margin-top:15px">
  
          
      
        <table class="table tablesorter"  id="datatable1" >
                                    <thead class="thead-light">
                                      <tr>
                                        <th data-priority="1">Contacto</th>
                                       
                                       
                         
                                      
                                       
                                      
                                        <th data-priority="2">Acción</th>
                                      </tr>
                                    </thead>
                                    <tbody>
      
      
                                      <tr v-for="transaccion in contactos" :key="transaccion.id">
      
                                       <td>
                                         <p style="font-weight: bold;margin:0px; text-align: center;"> {{ transaccion.firstName }} {{ transaccion.lastName }}</p>
                                         <p style="margin:0px; text-align: center;font-size:12px"> {{ transaccion.phoneNumber }}</p>
                                         <p v-if="transaccion.email != ''" style="margin:0px; text-align: center;font-size:12px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;"> {{ transaccion.email }}</p>
                                        </td>
                                       
                 
                                        
                                         
                                             <td>

                                            <b-dropdown v-if="!isMobile" dropleft variant="light" size="sm"  no-caret>
                                                                <template  #button-content>

                                                                <feather-icon
                                                                icon="MoreVerticalIcon"
                                                                size="25"
                                                                class="MoreVerticalIcon"
                                                                />
                                                                


                                                                </template>
                                                                <b-dropdown-item @click="opciones('enviar',transaccion.id, transaccion.firstName, transaccion.lastName, transaccion.nameBank, transaccion.countryCode, transaccion.country, transaccion.numberAccount)" >
                                                                <feather-icon
                                                                icon="SendIcon"
                                                                size="15"
                                                                style="margin-right: 10px;"
                                                                />

                                                                Enviar dinero al exterior
                                                                </b-dropdown-item>
                                                                <b-dropdown-item @click="opciones('editar',transaccion.id, transaccion.firstName, transaccion.lastName, transaccion.nameBank, transaccion.countryCode, transaccion.country, transaccion.numberAccount)">
                                                                <feather-icon
                                                                icon="EditIcon"
                                                                size="15"
                                                                style="margin-right: 10px;"
                                                                />

                                                                Editar contacto
                                                                </b-dropdown-item>
                                                                <b-dropdown-item @click="opciones('eliminar',transaccion.id, transaccion.firstName, transaccion.lastName, transaccion.nameBank, transaccion.countryCode, transaccion.country, transaccion.numberAccount)">
                                                                
                                                                <feather-icon
                                                                icon="Trash2Icon"
                                                                size="15"
                                                                style="margin-right: 10px;"
                                                                />
                                                                Eliminar contacto</b-dropdown-item>
                                                            </b-dropdown>

                                                            <feather-icon
                                                              v-else
                                                                @click="openModalBottom(transaccion.id, transaccion.firstName, transaccion.lastName, transaccion.nameBank, transaccion.countryCode, transaccion.country, transaccion.numberAccount)"
                                                                icon="MoreVerticalIcon"
                                                                size="25"
                                                                class="MoreVerticalIcon"
                                                                />

                                                
                                            </td>

                                
      
                                      </tr>
                                    </tbody>
                                  </table>
                          
      
       </b-col>

       
       <vue-bottom-dialog v-if="dialogRemesas" :indentColor="indentColor" :topRadius="radius" v-model="dialogRemesas" :height="210"  :overlayColor="overlay">
  
            <b-row>   
            
            <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

            <h4 style="margin: 0;" class="font-weight-bolder">
                Opciones de remesas
                <hr>
            </h4>

            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12"> <hr></b-col>

            <div class="opciones_cripto">
                <label for="Enviar"> 

                  <feather-icon
              icon="SendIcon"
              size="20"
              style="margin-right: 10px"
              />Enviar dinero al exterior

                </label>
                <input id="Enviar"  type="radio" @click="openOpciones('enviar')"  value="Enviar" name="radio" />
            </div>

            <div class="opciones_cripto">
                <label for="Editar"> 

                  <feather-icon
              icon="EditIcon"
              size="20"
              style=" margin-right: 10px"
              />Editar contacto

                </label>
                <input id="Editar"  type="radio" @click="openOpciones('editar')"  value="Editar" name="radio" />
            </div>

            <div class="opciones_cripto">
                <label for="Eliminar"> 

                  <feather-icon
              icon="Trash2Icon"
              size="20"
              style=" margin-right: 10px"
              />Eliminar contacto

                </label>
                <input id="Eliminar"  type="radio" @click="openOpciones('eliminar')"  value="Eliminar" name="radio" />
            </div>




            </b-row>  



  </vue-bottom-dialog>


      
  <modal-edit-contactos v-if="openModalEditContacto" :banks="banks" :country-code="countryCode"  :contact-id= "contactId" :dni= "dni" :email= "email" :phone-number="phoneNumber" :type-document="typeDocument" :number-account="numberAccount" :type-account="typeAccount" :bank-code="bankCode" :country="country" :key="componentKeyReiniciarEditContacto" :first-name="firstName" :last-name="lastName" :name-bank="nameBank"  :user-id="userId" :token-auth="tokenAuth"  />
    

  <modal-enviar-remesas v-if="openModalEnviarRemesas" :contact-id= "contactId" :currency="currency" :min-remesa="minRemesa" :max-remesa="maxRemesa" :comision="comision" :number-account="numberAccount" :balance="balance" :rate="rate" :country="country" :key="componentKeyReiniciarEnviarRemesas" :first-name="firstName" :last-name="lastName" :name-bank="nameBank"  :user-id="userId" :token-auth="tokenAuth"  />
    

  <b-modal
          ref="modalEliminar"
          centered
          hide-footer
          title="Confirmación de eliminación de contacto"
        >
      <div class="d-block text-center">

       

          <b-form autocomplete="off"  @submit.prevent="eliminarContacto">
            <b-row>

                         <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >
                      <p style="font-weight: bold;     font-size: 14px; margin-bottom: 15px;">¿Estás seguro de eliminar a este contacto?</p>

                    </b-col>


           

                <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >

                    <div class="table-responsive">
                         <table class="table table-bordered" style="text-align: center;">

                          <tbody>
                            <tr>
                   
                              <td class="background_table">Contacto:</td>
                              <td class="background_td_table">{{firstName}} {{lastName}}</td>
                            
                            </tr>

                              <tr>
                   
                              <td class="background_table">Banco:</td>
                              <td class="background_td_table">{{nameBank}}</td>
                            </tr>

                             <tr>
                   
                              <td class="background_table">Pais:</td>
                              <td class="background_td_table">{{country}}</td>
                            </tr>

                     
                          
                          </tbody>

                        </table>
                        </div>
                 

                </b-col>

          

         

                      <b-col
                          sm="12"
                          md="12"
                          lg="2"
                          xl="2"
                          align="center"
                        >
                        </b-col>

               <b-col
                          sm="12"
                          md="12"
                          lg="8"
                          xl="8"
                        >

                          <p
                            class="textLabel"
                            style="text-align: center;margin-bottom: 10px;"
                          >
                            Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

                          <PincodeInput
                            v-model="pinEliminar"
                            required
                            placeholder="-"
                            :length="6"
                            :autofocus="false"
                            :secure="true"
                            :readonly="isActiveEliminar"
                          />

                        </b-col>
                   <b-col
                          sm="12"
                          md="12"
                          lg="2"
                          xl="2"
                          align="center"
                        >
                        </b-col>


       

           
                <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                          align="center"
                        >

           </b-col>



          
                        <b-col
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                          align="center"
                        >

                          <b-button
                            id="btn_eliminar"
                            ref="btn_eliminar"
                            style="margin-top:5px; margin-bottom: 5px;"
                            type="submit"
                            variant="primary"
                            :disabled="isActiveEliminar"
                            block
                          >

                            Eliminar contacto
                          </b-button>

                        </b-col>

                             <b-col
                          sm="12"
                          md="12"
                          lg="3"
                          xl="3"
                          align="center"
                        >

                     </b-col>

                     
              


          </b-row>

           </b-form>

      </div>

    </b-modal>

      
       </b-row>
      
       <b-row v-else-if="empty">
      
        <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
  
  <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
        <feather-icon
                  icon="ListIcon"
                  size="21"
                  class="color_icon"
                  /> Mis contactos de remesas
          </h4>

</b-col>
      
       <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">

        
           <feather-icon
            class="color_icon"
              icon="UsersIcon"
              size="50"
            />
      
                                           
      
                               <p class="errorTexto">
                              No se encontraron contactos
                            </p>
      
                    </b-col>
      
       <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">
      
                                 <b-button   variant="primary"  @click="refresh()" > 
                                               <feather-icon
                                                icon="RefreshCcwIcon"
                                                size="16"
                                               
                                                style="margin-right:5px; color:#fff"
                                                /> Recargar contactos </b-button>
      
      
       </b-col>
      </b-row>
      
      
      <b-row v-else>
      
       <b-col  cols="12" sm="12" md="12" xl="12" lg="12" align="center">
      
      
                   <feather-icon
                   icon="FrownIcon"
                   size="50"
                   />
                 <p class="errorTexto">
                      Ocurrió un error al cargar los datos
                    </p>
      
                    <b-button
                      id="btn_refresh"
                      ref="btn_refresh"
                      variant="primary"
                      @click="refresh"
                    >
      
                      Intentar de nuevo
      
                    </b-button>
                      
              
      
                    </b-col>
      </b-row>
  </b-card>
      </template>
      
      <script>
      import {
        BButton, BRow, BCol,BBadge, BCard,BSkeletonTable, BSkeleton, BDropdown,BDropdownItem, VBPopover, BForm
      } from 'bootstrap-vue'
      import "jquery/dist/jquery.min.js";
      import PincodeInput from 'vue-pincode-input'
      import $ from "jquery";
      import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
      import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
      import ModalEnviarRemesas from './ModalEnviarRemesas.vue'
      import ModalEditContactos from './ModalEditContactos.vue'

      export default {
        components: {
          BButton,
          VBPopover,
          ModalEnviarRemesas,
          PincodeInput,
          ModalEditContactos,
          BSkeleton,
          BForm,
          BSkeletonTable,
          BRow,
          BCol,
          BDropdown,BDropdownItem,
          BCard,
          BBadge
        },
        directives: {
    'b-popover': VBPopover,
  },
        props: ['userId','tokenAuth', 'columnas'],
        data() {
      


      
          return {
            isMobile:this.$isMobile,
            code200: false,
            loading: true,
             empty:false,
             contactos:[],
             countryCode:"", numberAccount:"",pinEliminar:"",phoneNumber:"",
             typeDocument: "",
            typeAccount: "",
            bankCode: "",
            dni: "",
            email: "",
            columns:3,
            body: document.getElementsByTagName('body')[0],
             dialogRemesas:false,
             isActiveEliminar:false,
             overlay:"#000000e6",
             banks:[],
              radius:"15px",
              indentColor:"#b0b3b8",
             openModalEnviarRemesas:false,
             openModalEditContacto:false,
                componentKeyReiniciarEnviarRemesas:0,
                componentKeyReiniciarEditContacto:0,
                firstName:"", lastName:"", nameBank:"", country:"",balance:0, rate:0, numberAccount:"", currency:"",comision:0,contactId:"", minRemesa:0, maxRemesa:0

  
                        
                      
                            
                         
                                
                  

      
          }
        },
        computed: {
      
        },
        watch: {

          dialogRemesas(newValue) {

        
            if(newValue){
              this.body.classList.add("overflow-hidden");
            }else{
              this.body.classList.remove("overflow-hidden");
            }



            },
      
        },
        mounted() {
  
          this.$eventBus.$on('reiniciarSendRemesas', () => {
      this.componentKeyReiniciarEnviarRemesas += 1
      this.openModalEnviarRemesas = false;
 
    });

    this.$eventBus.$on('reiniciarEditContactos', () => {
      this.componentKeyReiniciarEditContacto += 1
      this.openModalEditContacto = false;
 
    });

    
  
      
          this.loadTable();
      
      
      
      
      
        },
        methods: {

          openModalBottom(id, firstName, lastName, nameBank, countryCode, country, numberAccount){
            this.contactId =id;
            this.firstName =firstName;
              this.lastName =lastName;
              this.nameBank =nameBank;
              this.countryCode =countryCode;
              this.country =country;
              this.numberAccount =numberAccount;

              this.dialogRemesas=true;

          },

          openOpciones(type){
            if(type==="enviar"){
              this.sendRemesas();

            }else{
              if(type==="eliminar"){

                this.deleteContacto();

              }else{

                this.editContacto();

              }
            }

          },

          opciones(type,id, firstName, lastName, nameBank, countryCode, country, numberAccount){

            this.contactId =id;
            this.firstName =firstName;
              this.lastName =lastName;
              this.nameBank =nameBank;
              this.countryCode =countryCode;
              this.country =country;
              this.numberAccount =numberAccount;


            if(type==="enviar"){

              this.sendRemesas();

            }else{
              if(type==="eliminar"){


                this.deleteContacto();

              }else{
                this.editContacto();

              }
            }

            },
            
            deleteContacto(){

            this.dialogRemesas=false;

            this.$refs.modalEliminar.show()

            },
            eliminarContacto(event){

                      

                event.preventDefault();

                const userId_json = { userId: this.userId, pin: this.pinEliminar, contactId: this.contactId}
                const user_string = JSON.stringify(userId_json);
                    

                this.isActiveEliminar = true

                const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

                document.getElementById('btn_eliminar').innerHTML = '<img style="margin-left:10px; width:20px; " src="/img/loading2.svg" alt="Loading" />Eliminando'



                this.$https.post('/remesas/deleteContact/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                  this.isActiveEliminar = false

                  document.getElementById('btn_eliminar').innerHTML = 'Eliminar contacto';


                  if (response.data.code == 200) {


                    this.pinEliminar= "";
                    this.contactId="";

                        this.$toast.success(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })
                        

                      

                    this.$refs.modalEliminar.hide();

                    this.$eventBus.$emit('reiniciarListadoContactos');


                  } else {

                      this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })


                    if (response.data.code == 401) {
                      
                      localStorage.removeItem('userData')


 
                      
                      

                    

                      
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    }else{

                      if (response.data.code == 503) {

                            this.pinEliminar="";

                      }

                    }
                  }
                }).catch(error => {
                  this.$toast.error("Ocurrió un error inesperado", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                })

                },
                editContacto(){

                      this.$swal({

                              html:
                              '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Obteniendo datos',
                              showCloseButton: false,
                              showCancelButton: false,
                              focusConfirm: false,
                              allowEscapeKey: false,
                              allowOutsideClick: false,
                              showConfirmButton: false,
                              position: 'center',
                              })




                              const userId_json = {
                                  userId: this.userId, contactId:this.contactId
                              

                              }
                              const user_string = JSON.stringify(userId_json)


                              const dataEncripted = this.$encryptBackoffice.encrypt(user_string)




                              this.$https.post('/remesas/getContact/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                                  this.$swal.close()
                                  if (response.data.code == 200) {

                          
         
 

                                  this.phoneNumber=  response.data.contacto.phoneNumber;
                                  this.typeDocument=  response.data.contacto.typeDocument;
                       
                                  this.typeAccount=  response.data.contacto.typeAccount;
                                  this.bankCode=  response.data.contacto.bankCode;
                                  this.dni=  response.data.contacto.dni;
                                  this.email=  response.data.contacto.email;

                                  this.banks= response.data.banks;
     

                              

                          

                                  this.openModalEditContacto=true;
                                  this.dialogRemesas=false;




                                  } else {
                              

                                  this.$toast.error(response.data.message, {
                                      position: 'top-right',
                                      timeout: 3010,
                                      closeOnClick: true,
                                      pauseOnFocusLoss: false,
                                      pauseOnHover: true,
                                      draggable: true,
                                      draggablePercent: 0.4,
                                      showCloseButtonOnHover: false,
                                      hideProgressBar: true,
                                      closeButton: 'button',
                                      icon: true,
                                      rtl: false,
                                  })

                                  if (response.data.code == 401) {
                                      
                                      localStorage.removeItem('userData')


                        
                                      

                                  

                                      
                                      

                                      // Redirect to login page
                                      this.$router.push({ name: 'auth-login' })
                                  }
                                  }
                              }).catch(error => {
                                  this.$toast.error("Ocurrió un error inesperado", {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                  })
                              })

                      },

            sendRemesas(){

                this.$swal({

                        html:
                        '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Comprobando estado',
                        showCloseButton: false,
                        showCancelButton: false,
                        focusConfirm: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        position: 'center',
                        })




                        const userId_json = {
                            userId: this.userId, countryCode: this.countryCode, contactId:this.contactId
                        

                        }
                        const user_string = JSON.stringify(userId_json)


                        const dataEncripted = this.$encryptBackoffice.encrypt(user_string)




                        this.$https.post('/remesas/checkContact/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                            this.$swal.close()
                            if (response.data.code == 200) {


                      

                    
                            this.balance=  response.data.balance;
                            this.comision= response.data.comision;
                            this.minRemesa= response.data.minRemesa;
                this.maxRemesa= response.data.maxRemesa;
                            this.currency= response.data.currency;
                            this.rate=  response.data.rate;

                            
                            
                      

                 

                            this.openModalEnviarRemesas=true;
                            this.dialogRemesas=false;




                            } else {
                        

                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                            if (response.data.code == 401) {
                                
                                localStorage.removeItem('userData')


                          
                                
                                

                            

                                
                                

                                // Redirect to login page
                                this.$router.push({ name: 'auth-login' })
                            }
                            }
                        }).catch(error => {
                            this.$toast.error("Ocurrió un error inesperado", {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })
                        })

            },

      
      
          refresh() {
            this.$eventBus.$emit('reiniciarListadoContactos')
          }, loadTable(){
            
         
      
            this.$https.post('/remesas/searchContacts/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {
               this.loading=false;
 
       
                            if(response.data["code"] == 200){
                            
                           
                      
                             
      
                              this.contactos= response.data["contactos"];
       
                       
                                        setTimeout(() => {
                                          $("#datatable1").DataTable({
                                            responsive: true,
                                            "ordering": false,
                                            columnDefs: [
                                                { responsivePriority: 1, targets: 0 },
                                                { responsivePriority: 2, targets: -1 }
                                            ],
                                            "ordering": false,
                                            lengthMenu: [
                                              [5,10, 25, 50, -1],
                                              [5,10, 25, 50, "All"],
                                            ],
                                            pageLength: 5, 
                                               "language": {
                                                    "search": "Buscar:",
                                                    "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                      "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                    "searchPlaceholder": "Ingrese algún dato",
                                                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                    "processing": "Buscando. Espere por favor...",
                                                    "sLengthMenu":     "Mostrar _MENU_ registros",
                                                    "oPaginate": {
                                                            "sFirst":    "Primero",
                                                            "sLast":     "Último",
                                                            "sNext":     "Siguiente",
                                                            "sPrevious": "Anterior"
                                                        },
      
                                                    }
                                          });
                                        });
      
                                           this.code200=true;
      
                           
        
                                
                              }else{
      
                 
                                  
      
      
                                        if (response.data.code == 401) {
      
                                          this.$toast.error(response.data.message, {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                  })
      
      
                                          
                                          localStorage.removeItem('userData')


                             
                                          
      
                 
      
                                          
                                          
      
                                          // Redirect to login page
                                          this.$router.push({ name: 'auth-login' })
                                        } else {
      
                                           if (response.data.code == 404) {
      
      
      
                                            this.empty=true;
      
                                          }else{
      
                                               this.$toast.error(response.data.message, {
                                                position: 'top-right',
                                                timeout: 3010,
                                                closeOnClick: true,
                                                pauseOnFocusLoss: false,
                                                pauseOnHover: true,
                                                draggable: true,
                                                draggablePercent: 0.4,
                                                showCloseButtonOnHover: false,
                                                hideProgressBar: true,
                                                closeButton: 'button',
                                                icon: true,
                                                rtl: false,
                                              })
      
                                              this.code200 = false
      
                                          }
                                        
      
      
                                        }
      
                              }
                       }).catch((error) => {
      
                                this.loadTable();
      
                           
                       });
          }
      
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      </style>